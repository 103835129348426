export function swiper() {
/*
	const frontIntro = new Swiper('.front-intro__wrap', {
		autoplay: {
			delay: 4000,
			disableOnInteraction: true
		},
	  loop: true,
		speed: 1000,
	  autoHeight: false,
		slidesPerView: 3,
		spaceBetween: 40,
		initialSlide: 1,
		centeredSlides: true,
		resizeReInit: true,
		breakpoints: {
			768: {
				slidesPerView: 1,
				spaceBetween: 20
			}
		},
		navigation: {
	    nextEl: '.front-intro__btn.-prev',
	    prevEl: '.front-intro__btn.-next',
	  },
	});
*/
	let frontProduct = new Swiper('.front-products__slide', {
		// autoplay: {
		// 	delay: 4000,
		// 	disableOnInteraction: true
		// },
	  loop: true,
		speed: 1000,
	  autoHeight: false,
		slidesPerView: 4,
		spaceBetween: 20,
		initialSlide: 1,
		//centeredSlides: true,
		resizeReInit: true,
		breakpoints: {
			768: {
				slidesPerView: 2,
				//spaceBetween: 10
			},
			467: {
				slidesPerView: 1,
				//spaceBetween: 10
			},
		},
		navigation: {
	    nextEl: '.front-products__btn.-next',
	    prevEl: '.front-products__btn.-prev',
	  },
	});
}
