export function loading() {
	const loading = document.querySelector('.loading');

	if(loading){
		window.onload = function() {
			setTimeout(() => {
				loading.classList.add('is-loading');
			},500);
		};
	}
}
