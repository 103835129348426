export function snsShare() {
	const shareBtn = document.getElementsByClassName("share");

  for (let i = 0; i < shareBtn.length; i++) {
    shareBtn[i].addEventListener("click", function(e) {
      e.preventDefault();
      window.open(this.href, "SNS_window", "width=600, height=500, menubar=no, toolbar=no, scrollbars=yes");
    });
  }
}
