/* nav open/close
*********************************************/
export function nav() {
	const headerBtn = document.querySelector('.header__btn'),
				nav = document.querySelector('.nav'),
				navChild = document.querySelectorAll('.nav__child');

	headerBtn.addEventListener('click', () => {
		headerBtn.classList.toggle('is-opened');
		nav.classList.toggle('is-opened');
	}, false);

	for (let i = 0; i < navChild.length; i++) {
		navChild[i].addEventListener('click', () => {
			headerBtn.classList.toggle('is-opened');
			nav.classList.toggle('is-opened');
		}, false);
	}
}
